<template>
  <footer class="primary">
    <v-container>
      <v-row>
        <v-col cols="12" md="6" lg="5">
          <v-img lazy-src="@/assets/logo_white_footer.svg"
            :src="$store.state.endpointURL + $store.state.sections.footer?.logo?.logo_image"
            :title="$t('value solutions')"
            :max-width="$store.state.sections.footer?.logo?.logo_image != null ? '350' : '200'"
            :alt="$t('value solutions')"></v-img>

          <h3 class="text-h5 font-weight-bold mt-5"> {{ $store.state.sections?.footer?.logo[`text_${$i18n.locale}`] }}
          </h3>
        </v-col>
        <v-col cols="12" md="6" lg="7">
          <v-row>
            <v-col cols="12" md="5">
              <v-col cols="12" class="py-1">
                <h6 class="text-h6">
                  {{ $store.state.sections?.footer?.logo[`explore_${$i18n.locale}`] }}
                </h6>
              </v-col>

              <v-col cols="12" class="pa-1" v-for="(nav, i) in $store.state.sections?.header?.menu" :key="i">
                <v-btn plain class="subtitle-2" v-if="nav.active" color="white" :href="changeSection(i)">
                  {{ nav[`name_${$i18n.locale}`] }}
                </v-btn>
              </v-col>
            </v-col>



            <v-col cols="12" md="7">
              <v-col v-if="someContactUsActive" cols="12" class="py-1">
                <h6 class="text-h6">
                  {{ $t('contact') }}
                </h6>
              </v-col>

              <v-col cols="12" class="py-1" v-for="(contact, i) in $store.state.sections?.footer?.contact_us" :key="i">
                <div class="subtitle-1 d-flex align-center">
                  <a :href="contact.link || ''" rel="email" v-if="contact.active">
                    {{ contact[`name_${$i18n.locale}`] }} </a>
                </div>
              </v-col>

            </v-col>
            <v-col cols="12" class="px-8">
              <hr />
            </v-col>

            <v-col cols="12" class="px-8">
              <v-row align="center">

                <v-col cols="12" md="8" class="d-flex">
                  <span v-for="(social, i) in $store.state.sections?.footer?.social_media" :key="i">
                    <a :href="social.link" target="_blank" class="social me-2 d-flex"
                      v-if="social.active && !!social.link && !!social?.image">
                      <img :src="$store.state.endpointURL + social?.image" alt="social" height="22" width="22" />
                    </a>
                  </span>
                </v-col>

                <v-col cols="12" md="4" class="d-flex justify-center flex-column align-center">
                  <h6 class="subtitle-1 font-weight-bold">{{ $t('call any time') }}</h6>
                  <h4 class="text-h4  font-weight-bold" dir="ltr">{{ $store.state.sections?.footer?.mobile }}</h4>
                </v-col>

              </v-row>

            </v-col>


          </v-row>
        </v-col>
        <v-col cols="12">
          <hr />
        </v-col>
        <v-col cols="12">
          <h6 class="footer_text  mb-2 text-center">
            <a href="https://cloudsecretsksa.com/" target="_blank" :rel="$t('cloud secrets')">
              © {{ new Date().getFullYear() }} {{ $t('copyrights reserved') }}
              {{ $t('Al -Badawi Company, accountants and legal auditors') }} {{ $t(',') }}
              {{ $t('designed by') }} {{ $t('cloud secrets') }}
            </a>
          </h6>
        </v-col>
      </v-row>
    </v-container>
  </footer>
</template>


<script>
// import ContactUSForm from './ui/ContactUSForm.vue';


export default {
  name: "PageFooter",
  props: {},
  data: () => ({}),
  computed: {
    someContactUsActive() {
      return this.$store.state.sections?.footer?.contact_us.some(item => item.active === 1);
    }
  },
  watch: {},
  mounted() {
  },
  beforeDestroy: function () {
  },
  methods: {
    changeSection(index) {
      switch (index) {
        case 0: return '#landingSection'
        case 1: return '#expertsCreatives'
        case 2: return '#servicesSection'
        case 3: return '#introSection'
        case 4: return '#contactSection'
      }
    },
    scrollTo(sectionName) {
      this.$store.state.sidebarIsOpen = false;
      if (this.$route.path === '/') {
        if (this.$i18n.locale == 'ar')
          this.$vuetify.goTo(`#${sectionName}`)
        else
          this.$vuetify.goTo(`/${this.$i18n.locale}#${sectionName}`)
      } else {
        this.$router.push(`/${this.$i18n.locale}`);
        setTimeout(() => {
          this.$vuetify.goTo(`#${sectionName}`)
        }, 100);
      }
    }
  },
};
</script>



<style scoped lang="scss">
footer {
  color: #fff;
  text-transform: capitalize;
  padding: 6rem 0 0 0;

  a {
    color: #fff;
    text-transform: capitalize;
    text-decoration: none;
    font-weight: 600 !important;
  }

  .social {
    position: relative;
    padding: .7rem .9rem;
    background-color: #fff;
    transition: .3s;
    border-radius: 10px;

    &:hover {
      background-color: var(--accent);

      img {
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
      }

      i {
        color: #fff !important;
      }
    }
  }

  .text-h6 {
    transition: .3s;
    text-align: start;
    font-weight: 700 !important;
    padding: 0 10px;
  }

  .subtitle-2 {
    font-size: 16px !important;
  }

  .footer_text {
    font-size: 16px !important;
    padding: 0 10px;
    font-weight: 600;
    opacity: 0.5;
    transition: 0.3s;

    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: 600px) {
    .footer_text {
      font-size: 12px !important;
    }
  }

  .subtitle-2 {
    font-weight: 600;
  }

  hr {
    opacity: 0.2;
  }
}
</style>
